/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import 'gmf/controllers/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';
import gmfControllersAbstractDesktopController, {AbstractDesktopController}
  from 'gmf/controllers/AbstractDesktopController.js';
import ngeoGeolocationDesktop from 'ngeo/geolocation/desktop.js';
import config from 'gmf/controllers/AbstractAppController.js';

// Custom draw line-polygon. Redefine some ngeo src/draw/Controller.js and
// ngeo contribs/gmf/src/drawing/drawFeatureComponent.html things.
import grDrawLinePolygon from '../linepolygon/draw/load_and_override.js';
import gr_mapfish3GeofunctionsComponent from '../geofunctions/component.js';
import gr_mapfish3Base from '../gr_mapfish3module.js';

import EPSG2056 from '@geoblocks/proj/src/EPSG_2056.js';
import EPSG21781 from '@geoblocks/proj/src/EPSG_21781.js';
import Raven from 'raven-js/src/raven.js';
import RavenPluginsAngular from 'raven-js/plugins/angular.js';

import olStyleCircle from 'ol/style/Circle.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleStyle from 'ol/style/Style.js';


if (!window.requestAnimationFrame) {
  alert('Your browser is not supported, please update it or use another one. You will be redirected.\n\n'
    + 'Votre navigateur n\'est pas supporté, veuillez le mettre à jour ou en utiliser un autre. '
    + 'Vous allez être redirigé.\n\n'
    + 'Ihr Browser wird nicht unterstützt, bitte aktualisieren Sie ihn oder verwenden Sie einen anderen. '
    + 'Sie werden weitergeleitet.');
  window.location.href = 'https://geomapfish.org/';
}


/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {import('gmf/controllers/AbstractAppController.js').Config} config A part of the application
   *     config.
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   */
  constructor($scope, $injector) {
    super({
      srid: 2056,
      mapViewConfig: {
        center: [2760000, 1170000],
        zoom: 1,
        resolutions: [224.00000,140.00000,56.00000,28.00000,14.00000,7.00000,5.60000,2.80000,2.10000,1.40000,0.70000,0.56000,0.28000,0.21000,0.14000,0.07000,0.05600,0.02800,0.01400]
      }
    }, $scope, $injector);

    /**
     * @type {Array<string>}
     */
    this.searchCoordinatesProjections = [EPSG21781, EPSG2056, 'EPSG:4326'];

    /**
     * @type {number}
     */
    this.searchMaxzoom = 14;

    /**
     * @type {!Array<number>}
     */
    this.scaleSelectorValues = [800000, 500000, 200000, 100000, 50000, 25000, 20000, 10000, 7500, 5000, 2500, 2000, 1000, 750, 500, 250, 200, 100, 50];

    /**
     * @type {Array<string>}
     */
    this.elevationLayers = ['DTM2'];

    /**
     * @type {Object<string, import('gmf/mobile/measure/pointComponent.js').LayerConfig>}
     */
    this.elevationLayersConfig = {};

    /**
     * @type {string}
     */
    this.selectedElevationLayer = this.elevationLayers[0];

    /**
     * @type {Object<string, import('gmf/profile/component.js').ProfileLineConfiguration>}
     */
    this.profileLinesconfiguration = {
      'DTM2': {color: '#0000A0'},
    };

    /**
     * @type {Array<import('gmf/map/mousepositionComponent.js').MousePositionProjection>}
     */
    this.mousePositionProjections = [{
      code: EPSG2056,
      label: 'CH1903+ / LV95',
      filter: 'ngeoNumberCoordinates::{x}, {y} m'
    }, {
      code: EPSG21781,
      label: 'CH1903 / LV03',
      filter: 'ngeoNumberCoordinates::{x}, {y} m'
    }, {
      code: 'EPSG:4326',
      label: 'WGS84',
      filter: 'ngeoDMSCoordinates:2'
    }];

    // Allow angular-gettext-tools to collect the strings to translate
    /** @type {angular.gettext.gettextCatalog} */
    const gettextCatalog = $injector.get('gettextCatalog');
    gettextCatalog.getString('Add a theme');
    gettextCatalog.getString('Add a sub theme');
    gettextCatalog.getString('Add a layer');

    if ($injector.has('sentryUrl')) {
      const options = $injector.has('sentryOptions') ? $injector.get('sentryOptions') : undefined;
      const raven = new Raven();
      raven.config($injector.get('sentryUrl'), options)
        .addPlugin(RavenPluginsAngular)
        .install();
    }
    // handle icon change on geofunction tool button when selected geometry type change
    this.geofunctionsiconcallback = (value) => {
      $('.geofunctions-tool').removeClass('geofunctions-point');
      $('.geofunctions-tool').removeClass('geofunctions-line');
      $('.geofunctions-tool').removeClass('geofunctions-polygon');
      if (value) {
        $('.geofunctions-tool').addClass(`geofunctions-${value}`);
      }
    };

    // watch activation/deactivation of geofunction tool
    this.geofunctionactive = false;
    $scope.$watch(() => this.geofunctionsPanelActive, (newVal) => {
      this.geofunctionactive = newVal;
      $scope.$broadcast('geofunctionsPanelChange');
      if (!newVal) {
        this.geofunctionsiconcallback(null);
      }
    });

    const positionFeatureStyle = config.positionFeatureStyle || new olStyleStyle({
      image: new olStyleCircle({
        radius: 6,
        fill: new olStyleFill({color: 'rgba(230, 100, 100, 1)'}),
        stroke: new olStyleStroke({color: 'rgba(230, 40, 40, 1)', width: 2})
      })
    });

    const accuracyFeatureStyle = config.accuracyFeatureStyle || new olStyleStyle({
      fill: new olStyleFill({color: 'rgba(100, 100, 230, 0.3)'}),
      stroke: new olStyleStroke({color: 'rgba(40, 40, 230, 1)', width: 2})
    });

    /**
     * @type {import('ngeo/geolocation/desktop.js').DesktopGeolocationDirectiveOptions}
     */
     this.desktopGeolocationOptions = {
      positionFeatureStyle: positionFeatureStyle,
      accuracyFeatureStyle: accuracyFeatureStyle,
      zoom: config.geolocationZoom,
      autorotate: config.autorotate
    };

  }
}

/**
 * @hidden
 */
const module = angular.module('Appdesktop', [
  gr_mapfish3Base.name,
  gmfControllersAbstractDesktopController.name,
  grDrawLinePolygon.name,
  gr_mapfish3GeofunctionsComponent.name,
  ngeoGeolocationDesktop.name,
  config.name
]);

module.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
module.run(/* @ngInject */ ($templateCache) => {
  // @ts-ignore: webpack
  $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
});

module.controller('DesktopController', Controller);

module.constant('obj','none')

export default module;
