import angular from 'angular';
import ngeoDrawController from 'ngeo/draw/Controller.js';
import ngeoMiscFilters from 'ngeo/misc/filters.js';
import ngeoGeometryType from 'ngeo/GeometryType.js';
import * as olEvents from 'ol/events.js';
import {
    createLinepolygonInteraction,
    createJstsOL3Parser,
    lineToLinepolygon
} from '../linepolygon.js';
import {BUFFER_NAME} from '../linepolygonBuffer.js';

/**
 * @type {!angular.IModule}
 * @hidden
 */
const module = angular.module('grDrawlinepolygon', [
  ngeoDrawController.name,
  ngeoMiscFilters.name,
]);

/**
 * Strongly inspired by ngeo/measure/length.js
 * @param {!angular.ICompileService} $compile Angular compile service.
 * @param {!angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
 * @param {!angular.IFilterService} $filter Angular filter.
 * @param {!angular.auto.IInjectorService} $injector Main injector.
 * @return {!angular.IDirective} The directive specs.
 * @ngInject
 * @ngdoc directive
 * @ngname grDrawlinepolygon
 */
const measureLengthComponent = function($compile, gettextCatalog, $filter, $injector) {
  return {
    restrict: 'A',
    require: '^^ngeoDrawfeature',
    /**
     * @param {!angular.IScope} $scope Scope.
     * @param {JQuery} element Element.
     * @param {angular.IAttributes} attrs Attributes.
     * @param {import('ngeo/draw/Controller.js').DrawController} drawFeatureCtrl Controller.
     */
    link: ($scope, element, attrs, drawFeatureCtrl) => {
      const measureLength = createLinepolygonInteraction($scope, $filter, $injector,
          $compile, gettextCatalog);
      drawFeatureCtrl.registerInteraction(measureLength);

      // === Start custom gr_webmap ===
      drawFeatureCtrl.drawLinePolygon = measureLength;

      // Use same name than the one passed to the
      // gr-linepolygonbuffer directive !
      measureLength.set('drawLinePolygonInteraction', true);

      const jstsOL3Parser = createJstsOL3Parser();

      const handleDrawEnd = (event) => {
        const buffer = measureLength.get(BUFFER_NAME);
        const line = event.detail.feature;
        event.detail.feature = lineToLinepolygon(line, buffer, jstsOL3Parser);
        const type = ngeoGeometryType.POLYGON;
        drawFeatureCtrl.handleDrawEnd(type, event);
      };

      olEvents.listen(
        measureLength,
        'measureend',
        handleDrawEnd.bind(drawFeatureCtrl),
      );
      // === END custom gr_webmap ===

      olEvents.listen(
        measureLength,
        'change:active',
        drawFeatureCtrl.handleActiveChange,
        drawFeatureCtrl
      );
    }
  };
}

module.directive('grDrawlinepolygon', measureLengthComponent);

export default module;
