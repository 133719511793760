import angular from 'angular';

export const BUFFER_NAME = 'linepolygonBuffer';

/**
 * Display a buffer input and set this value in a specified ol interaction.
 * @type {!angular.IModule}
 * @hidden
 */
const module = angular.module('grLinepolygoneBuffer', []);

class bufferController {

  /**
   * @param {angular.Scope} $scope Scope.
   * @param {gmf.theme.Themes} gmfThemes gmf Themes service.
   * @ngInject
   */
  constructor($scope) {
  
    /**
     * @type {boolean}
     */
    this.active = false;
  
    /**
     * @type {!ol/Map.js}
     */
    this.map;

    /**
     * @type {!string}
     */
    this.targetInteractionName;
  
    /**
     * @type {number}
     * @private
     */
    this.buffer_ = 5.7;
  
    /**
     * @type {ol/interaction/Interaction)}
     * @private
     */
    this.drawLinePolygonInteraction_;
  
    // Set the active property of this directive with the specified interaction
    // Useful to use the 'active' value in the html (to ng-show the directive).
    $scope.$watch(
      () => this.drawLinePolygonInteraction_ && this.drawLinePolygonInteraction_.getActive(),
      (active) => this.active = active
    );
  }
  
  /**
   * Get the interaction from its name and set an initial buffer.
   */
  $onInit() {
    this.drawLinePolygonInteraction_ = this.map.getInteractions().getArray().find(
      (interaction) => interaction.get(this.targetInteractionName) === true
    );
    this.getSetBuffer(this.buffer_);
  }
  
  /**
   * Get / set the buffer.
   * @param {number} value the new buffer.
   * @return {number}
   */
  getSetBuffer(value) {
    if (value !== undefined) {
      this.buffer_ = value;
      this.drawLinePolygonInteraction_.set(BUFFER_NAME, this.buffer_);
    }
    return this.buffer_;
  }
}

module.controller('grLinepolygonbufferController', bufferController);

/**
 * @return {angular.Directive} The directive specs.
 * @ngInject
 */
const linePolygonBuffer = function() {
  return {
    restrict: 'E',
    controller: 'grLinepolygonbufferController as ctrl',
    scope: {
      'active': '=',
      'map': '<',
      'targetInteractionName': '<targetinteractionname',
    },
    bindToController: true,
    template: require('./linepolygonBuffer.html')
  };
};

module.directive('grLinepolygonbuffer', linePolygonBuffer);

export default module;
