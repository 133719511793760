import angular from 'angular';
import {DrawController} from 'ngeo/draw/Controller.js'
import GmfDrawFeatureComponent from 'gmf/drawing/drawFeatureComponent.js';
import grDrawLinepolygon from './drawLinepolygon.js';
import grLinepolygoneBuffer from '../linepolygonBuffer.js';

/**
 * Add drawLinePolygon interaction variable to ngeo/draw/Controller.js
 * @type {import("ol/interaction/MeasureLength.js").default}
 */
DrawController.prototype.drawLinePolygon;

/**
 * Override gmf/drawing/drawFeatureComponent partial with a custom one.
 */
GmfDrawFeatureComponent.run(/* @ngInject */ ($templateCache) => {
  // @ts-ignore: webpack
  $templateCache.put('gmf/drawing/drawFeatureComponent', require('./drawFeatureComponent.html'));
});

// Set a module with all drawLinePolygon ngeo stuff.
const module = angular.module('grDrawfeature', [
  grDrawLinepolygon.name,
  grLinepolygoneBuffer.name,
]);

export default module;
